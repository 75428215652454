import { useGtm } from '@gtm-support/vue-gtm';

export const useCustomGtmEvent = () => {
    const gtm = useGtm();

    function trackEvent(eventParameters) {
        gtm.trackEvent({
            ...eventParameters
        });
    }

    return { trackEvent };
};